<template>
  <div id="comment-management">

    <table-action-row
      :filterList="listQuery"
      @onFilterChange="value => { listQuery = value; getList() }"
    />

    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      :data="list">
      <el-table-column :label="$t('DATE')" width="100">
        <template slot-scope="{row}">
          {{ row.createdDate != null ? moment(row.createdDate).format(DATE_FORMAT) : null }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('TIME')" width="100">
        <template slot-scope="{row}">
          {{ row.createdDate != null ? moment(row.createdDate).format(TIME_FORMAT) : null }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('ARTICLE_NAME')" width="200">
        <template slot-scope="{row}">
          {{ row.article ? row.article.title : null | translate }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('ARTICLE_LINK')" width="150">
        <template slot-scope="{row}">
          <el-link 
            type="primary"
            @click="goToArticle(row.article,true)"
          >
            {{ $t("CLICK_TO_OPEN") }}
          </el-link>
          <i 
            class="custom-icon mini pointer icon-copy"
            @click="copyArticleLink(row.article)"
          />
        </template>
      </el-table-column>
      <el-table-column :label="$t('COMMENT')" :width="isMobile ? 300 : null">
        <template slot-scope="{row}">
          {{ row.text }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('MEMBER')" :width="isMobile ? 120 : 150">
        <template slot-scope="{row}">
          {{ getFullName(row.user) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('STATUS')"
        width="120"
      >
        <template slot-scope="{row}">
          <div>
            <span :class="getStatusClass(row.status)">{{ $t(row.status) }}</span>
            <el-dropdown
              class="action-button"
              :hide-on-click="false"
              trigger="click"
            >
              <i class="custom-icon icon-edit mini center-in-div pointer"/>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="goTo(`/bo/comment/detail/${row.id}`)"
                >
                  {{ (fullAccess && row.status != 'ARCHIVED') ? $t("EDIT") : $t("VIEW") }}
                </el-dropdown-item>
                <el-dropdown-item 
                  v-if="isAdmin && row.status != 'ARCHIVED'" 
                  @click.native="handleArchive(row.id)"
                >
                  <el-divider class="no-margin" />
                  {{ $t("ARCHIVE") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import { generalMixin } from '@/utils/general-mixin.js';
import TableActionRow from '@/components/table/table-action-row.vue'
import Pagination from '@/components/table/pagination.vue'
import cloneDeep from "lodash/cloneDeep"
import moment from 'moment'
import { PAGE_SIZES } from "@/constants";
import { getCommentList, archiveCommentById } from "@/api/comment"

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  showSuspended: true
};

export default {
  name: "CommentManagement",
  mixins: [generalMixin],
  components: {
    Pagination, TableActionRow
  },
  data() {
    return {
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,
      // Data
      list: [],
      moment,
    }
  },
  computed: {
    searchKeyword: {
      get() { return this.listQuery.search },
      set(value) {
        this.listQuery.search = value
      }
    }
  },
  methods: {
    handleReset() {
      this.listQuery = cloneDeep(filterQuery)
      this.handleFilter();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch == false) {
        this.searchKeyword = ""
        this.getList()
      }
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    getList() {
      // console.log('Sending: ', this.listQuery)
      this.isLoading = true
      getCommentList(this.listQuery)
      .then(res => {
        if (res.status != 'ok') return
        this.list = cloneDeep(res.result.list)
        this.total = res.result.total

        // Back to previous page if empty data page
        const page = res.result.page
        const finalPage = res.result.finalPage
        if (finalPage < page) {
          this.listQuery.page = finalPage;
          this.getList();
        }

      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    },
    handleArchive(id) {
      if (!this.isAdmin) return
      this.$confirm(this.$t("ARCHIVE_CONFIRMATION"), this.$t("ARE_YOU_SURE"), {
        confirmButtonText: this.$t("ARCHIVE"),
        cancelButtonText: this.$t("CANCEL"),
        type: 'warning'
      })
      .then(() => {
        this.isLoading = true
        archiveCommentById(id).then(res => {
          if (res.status == 'ok') {
            this.$notify({
              type: 'success',
              message: this.$t("ARCHIVED_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            })
          }
        })
        .catch(this.handleError)
        .finally(() => this.getList())
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/table.scss";
#comment-management {
  position: relative;
  @include newsily-bo-table;
  .icon-copy,
  .action-button {
    float: right;
  }
}
</style>